<template>
  <div class="card card-custom">
    <div class="card-body">
      <physical-person-create-wizard :error-messages="physicalPersonWizardErrors" :is-employee="isEmployee"
        :nations="nations" can-add-as-employee @submit-as-employee="onPhysicalPersonSubmitAsEmployee"
        @submit="onPhysicalPersonSubmit"></physical-person-create-wizard>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PhysicalPersonCreateWizard from "@/view/components/wizards/PhysicalPersonCreateWizard.vue";
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";

import Swal from "sweetalert2";
import { createPhysicalPerson } from "@/core/abstractions/physicalPerson";
import { backendErrorSwal } from "@/core/helpers/swal";
import { mapGetters, mapState } from "vuex";
import { createEmployee } from "@/core/abstractions/employee";
import { successToast } from '@/core/helpers';
export default {
  components: {
    PhysicalPersonCreateWizard,
  },

  props: {
    isEmployee: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      physicalPersonWizardErrors: {},
      nations: [],
    };
  },

  computed: {
    ...mapGetters("user", ["getCurrentManagedCompanyId"]),
    ...mapState("constants", ["countries"]),

  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Physical person") },
      { title: this.$t("Create") },
    ]);
    this.nations = this.countries
  },

  methods: {
    async onPhysicalPersonSubmitAsEmployee(form, addContract) {
      try {
        const pp = await createPhysicalPerson(form);
        await createEmployee(pp.id, this.getCurrentManagedCompanyId, null);
        successToast(this.$t("Physical Person created successfully"))
        if (addContract) {
          this.$router.push({ name: "associate-contract", params: { ppId: pp.id } });
        } else {
          this.$router.push({ name: "employees-manage" });
        }
      } catch (err) {
        if (typeof err == "object") {
          this.physicalPersonWizardErrors = err;
          return;
        }
        await backendErrorSwal(err);
      }
    },

    async onPhysicalPersonSubmit(form) {
      try {
        await PhysicalPersonService.create(form);
        successToast(this.$t("Physical Person created successfully"));
        this.$router.push({ name: "physical-person" });
      } catch (err) {
        console.log(err)
        if (err?.response?.status === 400) {
          this.physicalPersonWizardErrors = err.response.data;
          const socialError = err.response.data?.socialcontact_set["0"];
          if (socialError?.persona) {
            await Swal.fire(
              this.$t("Unable to create Physical Person"),
              this.$t("Field persona is required to associate social contacts"),
              this.$t("error"),
            );
          } else {
            await backendErrorSwal(err, err?.response?.data?.detail);
          }
        } else {
          await backendErrorSwal(err, err?.response?.data?.detail);
        }
      }
    },
  },
};
</script>
