import i18n from "@/core/plugins/vue-i18n";
import EmployeeService from "../services/employee/employee.service";

async function createEmployee(personId, companyId, contract) {
  const body = {
    persona: personId,
    company: companyId,
    employeecontract_set: contract ? [contract] : [],
  };

  try {
    return await EmployeeService.associateContract(body);
  } catch (err) {
    if (err?.response?.status === 400) {
      throw err.response.data;
    }

    throw err?.response?.data?.detail ?? i18n.t("FAILED_TO_CREATE");
  }
}


async function getAllEmployeesNotPaginated() {
  // const { count } = await EmployeeService.getAll({perPage: 1, page: 1})
  const count = 100

  return EmployeeService.getAll({perPage: count, page: 1})
}

export { createEmployee, getAllEmployeesNotPaginated };
