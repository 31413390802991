var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('physical-person-create-wizard', {
    attrs: {
      "error-messages": _vm.physicalPersonWizardErrors,
      "is-employee": _vm.isEmployee,
      "nations": _vm.nations,
      "can-add-as-employee": ""
    },
    on: {
      "submit-as-employee": _vm.onPhysicalPersonSubmitAsEmployee,
      "submit": _vm.onPhysicalPersonSubmit
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }